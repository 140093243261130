import cardinal from './assets/portfolio/cardinal.png'
import cardinalW from './assets/portfolio/cardinal.webp'
import ltt from './assets/portfolio/ltt.png'
import lttW from './assets/portfolio/ltt.webp'
import ezee from './assets/portfolio/ezee.png'
import ezeeW from './assets/portfolio/ezee.webp'
import velez from './assets/portfolio/velez.png'
import velezW from './assets/portfolio/velez.webp'
import whitepc from './assets/packaging/5.png'
import whitepcW from './assets/packaging/5.webp'

export const PortfolioData = [
    {
        name: 'Cardinal Services Group',
        desc: 'Hvac company, Windsor, ON',
        img: cardinal,
        webp: cardinalW,
        link: 'https://www.cardinalservicesgroup.com',
    },
    {
        name: 'London Top Tailoring',
        desc: 'Best Tailor, London, ON',
        img: ltt,
        webp: lttW,
        link: 'https://www.londontoptailoring.com',
    },
    {
        name: 'Ezee Computer Training',
        desc: 'Computer training, London, ON',
        img: ezee,
        webp: ezeeW,
        link: 'https://www.ezeecomputertraining.com',
    },
    {
        name: 'Velezfit',
        desc: 'Personal training, London, ON',
        img: velez,
        webp: velezW,
        link: 'http://www.velezfit.ca',
    },
]

export const PackagingData = [
    {
        name: 'White Packaging Presentation Clicker',
        desc: 'Vancouver, BC, Canada',
        img: whitepc,
        webp: whitepcW,
        link: 'https://www.londontoptailoring.com',
    },
]
