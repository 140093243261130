import { Center, Container, Flex, Heading, Text } from '@chakra-ui/react'

export default function PrivayPolicyPage() {
    return (
        <>
            <Container
                fontSize={16}
                maxW={'7xl'}
                textAlign={'left'}
                py={6}
                centerContent
            >
                <Center>
                    <Heading as={'h4'} size={'lg'} py={6}>
                        Privacy Policy
                    </Heading>
                </Center>
                <Flex style={styles.p} flexDirection={'column'}>
                    <Text style={styles.text}>
                        The website www.elementarycreative.com is owned and
                        maintained by Elementart Creative , henceforth termed as
                        “we”, “our”, “us”, or the “company”. All the visitors,
                        users are named as "users"; and the website
                        elementarycreative.com is termed as "website" everywhere
                        in this document.
                    </Text>
                    <Text style={styles.text}>
                        We are committed to protecting your privacy online. This
                        privacy policy explains what information we collect from
                        you or what information you share with us when you visit
                        the website. We review our policy from time to time, so
                        you are advised to check the latest version. As a user
                        of www.elementarycreative.com, you must agree to have
                        read our privacy policy before sharing any information
                        with us. This Privacy Policy is incorporated into and
                        subject to the Terms of Use.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Information we collect
                    </Heading>
                    <Text style={styles.text}>
                        The visitors are free to navigate through our website
                        without providing any personally identifiable
                        information if they do not want to register with us. To
                        gain access to some of our products and services, you
                        need to register with us and obtain an account, user
                        name, and password. If you choose to register with us,
                        and are 18 years of age or older, we will collect your
                        personally identifiable information such as your name,
                        email address, and phone number. We do not collect more
                        information than is absolutely necessary to allow your
                        participation in an activity on the website.
                    </Text>
                    <Text style={styles.text}>
                        We collect and analyze information about your general
                        usage of the website, products, services. We might track
                        your usage patterns to see what features of the website
                        you commonly use, website traffic volume, frequency of
                        visits, type and time of transactions, type of browser,
                        browser language, IP address and operating system, and
                        statistical information about how you use our products
                        and services. We only collect, track and analyze such
                        information in an aggregate manner that does not
                        personally identify you.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        How do we use your information?
                    </Heading>
                    <Text style={styles.text}>
                        We do not sell your personal information to others. The
                        personally identifiable Information you submit to
                        receive information and services from our website will
                        never be disclosed to any third party. We use this
                        personally identifiable information to register you to
                        access our services and verify your authority to access
                        the courses and services.
                    </Text>
                    <Text style={styles.text}>
                        We use the collected information to improve our website
                        and to send you relevant information about our products
                        and services which we think may be of interest to you.
                        If you have subscribed for a service on our website,
                        then your personally identifiable information may be
                        used to enable us to serve you better.
                    </Text>
                    <Text style={styles.text}>
                        We use the collected website information such as your
                        usage patterns and how you access and use our products
                        and services to help us improve our offerings and assist
                        us in operating the website better.
                    </Text>
                    <Text style={styles.text}>
                        Unless you have informed us that you do not wish to
                        receive further information about our products and
                        services, we may send you direct promotional mails
                        regarding our products and services. In case you don’t
                        want us to feature your testimonials or don’t want to
                        receive further information about our products or
                        services, please contact us.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Children's privacy
                    </Heading>
                    <Text style={styles.text}>
                        We are committed to protecting children's privacy
                        online. This website is intended for users above the age
                        of 13. We do not knowingly collect information from
                        children. We do not send unsolicited direct email to
                        users who have indicated on registering of those pages
                        that they are under 13. Children under 13 should ask a
                        parent, guardian or teacher for permission before
                        sending us information online.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Use of cookies
                    </Heading>
                    <Text style={styles.text}>
                        A cookie is a small file containing an identifier (a
                        string of letters and numbers) that is sent by a web
                        server to a web browser and is stored by the browser.
                        The identifier is then sent back to the server each time
                        the browser requests a page from the server. Cookies can
                        be used by web servers to identify and track users as
                        they navigate different pages on a website and identify
                        users returning to a website. Cookies do not typically
                        contain any information that personally identifies a
                        user, but personal information that we store about you
                        may be linked to the information stored in and obtained
                        from cookies.
                    </Text>
                    <Text style={styles.text}>
                        Kindly be informed that our website uses cookies to
                        enhance our services based on user preferences. You can
                        set your browser to block cookies, or to warn you before
                        accepting them. Most parts of our website can be
                        accessed even if your cookies are turned off, however
                        you may find there are parts of the website that cannot
                        be accessed with your browser settings blocking the
                        cookies.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Links to other websites
                    </Heading>
                    <Text style={styles.text}>
                        Our website contains links to other websites. The users
                        need to take notice of the fact that the linked websites
                        are governed by their own privacy policies and we
                        disclaim any responsibility arising out of your actions
                        on the linked website. We advise users to go through the
                        privacy policy of the linked website and their terms and
                        conditions before sharing any information to these
                        websites.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Disclosure of information
                    </Heading>
                    <Text style={styles.text}>
                        We reserve the right to disclose your personally
                        identifiable information as required by law and when we
                        believe that disclosure doing so in the Company's
                        interest to protect its property or other legal rights
                        or the rights or property of others.
                    </Text>
                    <Text style={styles.text}>
                        Should the company have its assets sold to or merge with
                        another business entity, we reserve the right to
                        transfer the information we receive from this website
                        including your personally identifiable information to a
                        successor as a result of any such corporate
                        reorganization.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Retention of information
                    </Heading>
                    <Text style={styles.text}>
                        We will keep personally identifiable Information of our
                        users for as long as they are registered subscribers or
                        users of our products and services, and as permitted by
                        law.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Information correction
                    </Heading>
                    <Text style={styles.text}>
                        To update or correct your personally identifiable
                        information that we hold, you can notify us at
                        support@elementarycreative.com
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        Information security
                    </Heading>
                    <Text style={styles.text}>
                        We store all the collected information on our secure
                        servers. As a registered user, you can access your
                        account with a unique user name and a password as
                        selected by you. You are responsible for keeping your
                        password confidential. To ensure better security, we
                        recommend that you choose a strong password containing
                        alphabets, numbers, and special characters, and that you
                        change your password periodically.
                    </Text>
                    <Text style={styles.text}>
                        We employ the best mechanisms possible to protect your
                        Personal Information, however we cannot be held
                        responsible for any breach of security unless it is
                        caused as a direct result of our negligence.
                        Unfortunately, data transmission over the internet is
                        not 100% secure and as a registered user of this
                        website, you agree that we are not accountable for any
                        data loss due to the limitations of the internet which
                        are beyond our control.
                    </Text>
                    <Text style={styles.text}>
                        In the unlikely event of a breach in security systems,
                        we may notify you through email so that you can take
                        suitable protective measures. You are advised to inform
                        us immediately at contact@tutorialspoint.com in case
                        your user name or password is compromised.
                    </Text>
                    <Heading py={4} as={'h3'} size={'md'}>
                        International users
                    </Heading>
                    <Text style={styles.text}>
                        This website is operated from Canada. If you are an
                        international user visiting from other regions having
                        laws governing data collection and use that may differ
                        from the Canadian law, please note that you are
                        transferring your personal data to Canada, which does
                        not have the same data protection laws. By providing
                        your personal data you consent to the use of your
                        personal data for the uses identified above in
                        accordance with this Privacy Policy.
                    </Text>

                    <Heading py={4} as={'h3'} size={'md'}>
                        Contact us
                    </Heading>
                    <Text style={styles.text}>
                        If you have questions or concerns regarding this Policy,
                        please <a href="/contact">contact us</a>
                    </Text>
                </Flex>
            </Container>
        </>
    )
}

const styles = {
    p: {
        fontSize: '16px',
    },
    text: {
        marginTop: '5px',
        marginDown: '5px',
    },
}
