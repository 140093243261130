import {
    Box,
    Container,
    Flex,
    HStack,
    Icon,
    Stack,
    Text,
    VisuallyHidden,
    chakra,
    useColorModeValue,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FaFacebook, FaHeart, FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { COMMON_NAVLINKS } from '../../constants'
import GetStarted from '../GetStarted/GetStarted'

const Logo = (props: any) => {
    return <img src={logo} style={styles.photo} alt="Elementary Creative" />
}

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            target="_blank"
            rel="noreferrer"
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'700'} fontSize={'xl'} mb={2}>
            {children}
        </Text>
    )
}

export default function Footer() {
    return (
        <Box
            width="100%"
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            justifyItems={'center'}
        >
            <GetStarted />
            <Container as={Stack} maxW={'6xl'} py={10}>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={'space-around'}
                    justifyItems={'center'}
                    align={'top'}
                >
                    <HStack
                        w={{ base: '100%', md: '60%' }}
                        align={'top'}
                        justifyContent={'space-around'}
                    >
                        <Stack align={'flex-start'} sx={styles.links}>
                            <ListHeader>COMPANY</ListHeader>

                            <Link to={COMMON_NAVLINKS.contact.link}>
                                {COMMON_NAVLINKS.contact.label}
                            </Link>
                            <Link to={'/portfolio'}>Portfolio</Link>

                            <Link to={COMMON_NAVLINKS.testimonials.link}>
                                {COMMON_NAVLINKS.testimonials.label}
                            </Link>
                        </Stack>
                        <Stack align={'flex-start'} sx={styles.links}>
                            <ListHeader>INFO</ListHeader>
                            <Link to={COMMON_NAVLINKS.privacyPolicy.link}>
                                {COMMON_NAVLINKS.privacyPolicy.label}
                            </Link>
                            <Link to={COMMON_NAVLINKS.services.link}>
                                {COMMON_NAVLINKS.services.label}
                            </Link>
                        </Stack>
                        <Stack align={'flex-start'} sx={styles.links}>
                            <ListHeader>AREAS WE SERVE</ListHeader>
                            <Text>Vancouver Web Design</Text>
                            <Text>Langley Web Design</Text>
                            <Text>Abbotsford Web Design</Text>
                            <Text>Maple Ridge Web Design</Text>
                        </Stack>
                    </HStack>
                    <Stack mt={{ base: 8, md: 0 }} align={'center'} spacing={6}>
                        <Link to={'/'}>
                            <Logo
                                color={useColorModeValue('gray.700', 'white')}
                            />
                        </Link>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton
                                label={'Facebook'}
                                href={
                                    'https://www.facebook.com/elementarycreativeofficial/'
                                }
                            >
                                <FaFacebook />
                            </SocialButton>
                            <SocialButton
                                label={'Instagram'}
                                href={
                                    'https://www.instagram.com/elementarycreative/'
                                }
                            >
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>

                    {/* <Stack align={'flex-start'}>
                      <ListHeader>Stay up to date</ListHeader>
                      <Stack direction={'row'}>
                          <Input
                              placeholder={'Your email address'}
                              bg={useColorModeValue(
                                  'blackAlpha.100',
                                  'whiteAlpha.100'
                              )}
                              border={0}
                              _focus={{
                                  bg: 'whiteAlpha.300',
                              }}
                          />
                          <IconButton
                              bg={useColorModeValue('green.400', 'green.800')}
                              color={useColorModeValue('white', 'gray.800')}
                              _hover={{
                                  bg: 'green.600',
                              }}
                              aria-label="Subscribe"
                              icon={<BiMailSend />}
                          />
                      </Stack>
                  </Stack> */}
                </Flex>
            </Container>

            <Stack
                align={'center'}
                py={{ base: 12, sm: 8 }}
                height={16}
                bg={'black'}
                direction={{ base: 'column', sm: 'row' }}
                gap={'sm'}
                justify={'space-around'}
                color={'white'}
                fontSize={'md'}
            >
                <Text>© 2024 Elementary Creative. All rights reserved</Text>
                <Text>
                    Made with{' '}
                    {<Icon color={'pink.500'} as={FaHeart} size="sm" />} by{' '}
                    <a
                        href="https://www.elementarycreative.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Elementary Creative
                    </a>
                </Text>
            </Stack>
        </Box>
    )
}
const styles = {
    photo: {
        height: '50px',
    },
    links: {
        textAlign: 'left',
        fontSize: 16,
        '& a': {
            '&:hover': { color: 'pink.400' },
        },
    },
}
