import {
    Box,
    ChakraProvider,
    Grid,
    VStack,
    extendTheme,
} from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom'
// import { ColorModeSwitcher } from "./ColorModeSwitcher"
import ContactPage from './Pages/ContactPage/ContactPage'
import HomePage from './Pages/HomePage/HomePage'
import PortfolioPage from './Pages/PortfolioPage/PortfolioPage'
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage/PrivacyPolicyPage'
import ServicesPage from './Pages/ServicesPage/ServicesPage'
import Footer from './components/Footer/Footer'
import Navbar from './components/Navbar/Navbar'
import GraphicPage from './Pages/GraphicPage/GraphicPage'

const theme = extendTheme({
    fonts: {
        heading: `'Open Sans', sans-serif`,
        body: `'Inter', sans-serif`,
    },
})

export const App = () => (
    <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl">
            <Navbar />
            <Grid minH="calc(100vh - 299px)" p={3}>
                {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
                <VStack spacing={8}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/portfolio" element={<PortfolioPage />} />
                        <Route path="/services" element={<ServicesPage />} />
                        <Route
                            path="/services/graphic-design"
                            element={<GraphicPage />}
                        />
                        <Route
                            path="/privacy-policy"
                            element={<PrivacyPolicyPage />}
                        />
                    </Routes>
                </VStack>
            </Grid>
            <Footer />
        </Box>
    </ChakraProvider>
)
