import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Icon,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    chakra,
    useColorModeValue,
} from '@chakra-ui/react'
// Here we have used react-icons package for the icons
import { useEffect, useState } from 'react'
import { BiCheck } from 'react-icons/bi'
import { FaStar } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { maintenancePlansList, plansList } from './pricingData'
import SingleTierPricing from './pricingCard/PricingCard'
import PricingCard from './pricingCard/PricingCard'

const colorOfStars = (stars: number) => {
    switch (stars) {
        case 1:
            return 'BlackAlpha.50'
        case 2:
            return 'gray.400'
        case 3:
            return 'yellow.300'
        case 4:
            return 'gray.100'
        case 5:
            return 'pink.300'
    }
}

const ServicesPage = () => {
    return (
        <Container
            maxW="8xl"
            minH={'100vh'}
            mt={12}
            py="4"
            centerContent
            px={0}
        >
            <chakra.h2
                fontSize="2xl"
                fontWeight="bold"
                textAlign="center"
                mb={12}
            >
                Simple and affordable pricing
            </chakra.h2>
            <Tabs isLazy isFitted variant="enclosed" color="#33186B">
                <TabList>
                    <Tab>Website Development</Tab>
                    <Tab>Website Maintenance</Tab>
                    {/* <Tab>Graphic Design</Tab>
                    <Tab>Business Card Design</Tab>
                    <Tab>Logo Design</Tab> */}
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex
                            wrap={'wrap'}
                            mt={4}
                            justifyContent={'center'}
                            columnGap={4}
                        >
                            {plansList.map((plan, index) => (
                                <PricingCard plan={{ ...plan, width: 'xs' }} />
                            ))}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex
                            wrap={'wrap'}
                            mt={4}
                            justifyContent={'center'}
                            columnGap={4}
                        >
                            {maintenancePlansList.map((plan, index) => (
                                <PricingCard plan={{ ...plan, width: 'sm' }} />
                            ))}
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default ServicesPage
