export const COMMON_NAVLINKS = {
    home: {
        label: 'Home',
        link: '/',
    },
    portfolio: {
        label: 'Portfolio',
        link: '/portfolio',
    },
    services: {
        label: 'Services',
        link: '/services',
    },
    contact: {
        label: 'Contact Us',
        link: '/contact',
    },
    privacyPolicy: {
        label: 'Privacy Policy',
        link: '/privacy-policy',
    },
    testimonials: {
        label: 'Testimonials',
        link: '/#testimonials',
    },
    cdapGrant: {
        label: '$15000 Grant',
        link: '/cdap-grant',
    },
}
