import {
    Container,
    Flex,
    Heading,
    Icon,
    Image,
    SimpleGrid,
    Stack,
    StackDivider,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { IoAnalyticsSharp, IoLogoBitcoin, IoSearchSharp } from 'react-icons/io5'

interface FeatureProps {
    text: string
    iconBg: string
    icon?: ReactElement
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}
            >
                {icon}
            </Flex>
            <Text textAlign={'left'} fontWeight={600}>
                {text}
            </Text>
        </Stack>
    )
}

export default function Story() {
    return (
        <Container maxW={'7xl'} py={12} centerContent>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Stack spacing={4}>
                    <Heading size={'lg'}>Elementary Solutions</Heading>
                    <Text textAlign={'left'} color={'gray.700'} fontSize={'lg'}>
                        Elementary Creative is here to help you take your story
                        and share it with the world. Having worked with some
                        amazing clients, we learned that it doesn't just end
                        with a website, it ends with a relationship.
                        Understanding your business, your competition, and your
                        brand is how we can really help you get your true story
                        out while driving real lead conversion.
                    </Text>
                    <Text textAlign={'left'} color={'gray.700'} fontSize={'lg'}>
                        We are known for perfection in every project that we
                        take. Delivering the best experience and making flawless
                        applications is our primary focus. We follow the most
                        stable frameworks that are the industry standards and
                        are time tested for steady performance.
                    </Text>
                    <Stack
                        spacing={4}
                        divider={
                            <StackDivider
                                borderColor={useColorModeValue(
                                    'gray.100',
                                    'gray.700'
                                )}
                            />
                        }
                    >
                        <Feature
                            icon={
                                <Icon
                                    as={IoAnalyticsSharp}
                                    color={'yellow.500'}
                                    w={5}
                                    h={5}
                                />
                            }
                            iconBg={useColorModeValue(
                                'yellow.100',
                                'yellow.900'
                            )}
                            text={'Small Business Websites'}
                        />
                        <Feature
                            icon={
                                <Icon
                                    as={IoLogoBitcoin}
                                    color={'green.500'}
                                    w={5}
                                    h={5}
                                />
                            }
                            iconBg={useColorModeValue('green.100', 'green.900')}
                            text={'E-commerce websites'}
                        />
                        <Feature
                            icon={
                                <Icon
                                    as={IoSearchSharp}
                                    color={'purple.500'}
                                    w={5}
                                    h={5}
                                />
                            }
                            iconBg={useColorModeValue(
                                'purple.100',
                                'purple.900'
                            )}
                            text={'Appointment booking setup with reminders'}
                        />
                    </Stack>
                </Stack>
                <Flex>
                    <Image
                        rounded={'md'}
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    )
}
