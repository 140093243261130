export const plansList = [
    {
        title: 'Landing Page',
        price: 699,
        stars: 1,
        features: ['1 Page', 'Contact Form', 'Cancel anytime'],
    },
    {
        title: 'Silver',
        price: 1799,
        stars: 2,
        features: [
            '5 Pages',
            'Contact Form',
            'Cancel anytime',
            'Free SSL',
            'Sub-pages',
        ],
    },
    {
        title: 'Gold',
        price: 2599,
        stars: 3,
        features: [
            '8 Pages',
            'Contact Form',
            'Cancel anytime',
            'Free SSL',
            'Sub-pages',
            'Appointment Booking',
            'Calendar Integration',
        ],
    },
    // {
    //     title: 'Platinum',
    //     price: 3499,
    //     stars: 4,
    //     features: [
    //         '10 Pages',
    //         'Contact Form',
    //         'Cancel anytime',
    //         'Free SSL',
    //         'Sub-pages',
    //         'Appointment Booking',
    //         'Calendar Integration',
    //         'Live Chat Integration',
    //         'Custom feature',
    //     ],
    // },
    {
        title: 'Custom',
        price: 5499,
        stars: 5,
        features: [
            'Contact Form',
            'Cancel anytime',
            'Free SSL',
            'Sub-pages',
            'Custom logic',
            'E-commerce',
            'Training on adding and updating inventory',
            'Custom feature',
        ],
    },
]

export const maintenancePlansList = [
    {
        title: 'Basic',
        price: 59,
        stars: 1,
        features: ['Wordpress version update', 'Plugin Updates', 'Text Change'],
    },
    {
        title: 'Professional',
        price: 109,
        stars: 2,
        features: [
            'Wordpress version update',
            'Plugin Updates',
            'Add or remove e-com products',
            'Text Change',
            'Section Change',
        ],
    },
    {
        title: 'Complete',
        price: 169,
        stars: 3,
        features: [
            'Wordpress version update',
            'Plugin Updates',
            'Add or remove e-com products',
            'Text Change',
            'Section Change',
            'Full Page Change',
        ],
    },
]
