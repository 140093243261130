import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Skeleton,
    Stack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'

import { BiLinkExternal } from 'react-icons/bi'
import { PortfolioData } from '../../portfolio'

interface PortfolioItemProps {
    name: string
    desc: string
    img: string
    webp: string
    link: string
}

const PortfolioItem = ({ name, desc, img, webp, link }: PortfolioItemProps) => {
    return (
        <Box
            width={{ base: 'full', sm: '2xs', md: 'xs', lg: 'md', xl: 'lg' }}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            padding={1}
            boxShadow={
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
            }
        >
            <Image
                borderTopRadius={'lg'}
                fallback={<Skeleton w={'100%'} height={215.93} />}
                src={webp}
                alt={name}
            />

            <Box p="6">
                <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                >
                    {name}
                </Box>
                <Box isTruncated as="h5" fontSize={16} fontWeight="light">
                    {desc}
                </Box>
                <Center>
                    <a target="_blank" rel="noreferrer" href={`${link}`}>
                        <Button
                            mt={4}
                            p={4}
                            rightIcon={<BiLinkExternal />}
                            colorScheme="teal"
                            variant="outline"
                        >
                            View live
                        </Button>
                    </a>
                </Center>
            </Box>
        </Box>
    )
}

export default function Portfolio() {
    return (
        <Container maxW={'9xl'} py={16} as={Stack} spacing={12} centerContent>
            <Heading size="xl">Our work</Heading>
            <Wrap spacing="20px" align="center" justify="center">
                {PortfolioData.map((p) => {
                    return (
                        <WrapItem>
                            <PortfolioItem
                                name={p.name}
                                desc={p.desc}
                                img={p.img}
                                webp={p.webp}
                                link={p.link}
                            />
                        </WrapItem>
                    )
                })}
            </Wrap>
        </Container>
    )
}
