import {
    Box,
    Container,
    Flex,
    Icon,
    SimpleGrid,
    Stack,
    Text,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import {
    FcAssistant,
    FcCalendar,
    FcCheckmark,
    FcDataBackup,
    FcLock,
    FcMoneyTransfer,
} from 'react-icons/fc'

interface FeatureProps {
    title: string
    text: string
    icon: ReactElement
}

const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
        <Stack align={'center'}>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}
            >
                {icon}
            </Flex>
            <Text fontWeight={600}>{title}</Text>
            <Text fontWeight="medium" color={'gray.600'}>
                {text}
            </Text>
        </Stack>
    )
}

export default function Features() {
    return (
        <Container maxW={'7xl'} py={16} as={Stack} spacing={12} centerContent>
            <Box py={12}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    <Feature
                        icon={<Icon as={FcAssistant} w={10} h={10} />}
                        title={'Support'}
                        text={
                            'We are a small company based in Canada so you will get 1:1 support from us. No job too big or too small.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcCheckmark} w={10} h={10} />}
                        title={'Promise of Quality'}
                        text={
                            'We use AWS and Google Cloud on the backend to make sure your website is running on the best hardware.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcDataBackup} w={10} h={10} />}
                        title={'Backup'}
                        text={
                            'We do a monthly backup of your data, so in case things go wrong, we can restore the website to its original state.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcCalendar} w={10} h={10} />}
                        title={'Scheduling'}
                        text={
                            'We can help you setup online scheduling for your e-learning, car detailing or hair dressing business.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
                        title={'E-commerce'}
                        text={
                            'We can set up an ecommerce website for you that is cheaper than shopify depending on the amount of traffic you are expecting.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcLock} w={10} h={10} />}
                        title={'Security'}
                        text={
                            "All our websites are protected with SSL to ensure the safety of your and your customer's data."
                        }
                    />
                </SimpleGrid>
            </Box>
        </Container>
    )
}
