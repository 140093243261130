import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export default function Hero() {
    return (
        <>
            <Container
                maxW={'7xl'}
                minH={{ base: '90vh', sm: '60vh', md: '100vh' }}
                className="h-[45rem]"
                display={'flex'}
            >
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 6, md: 10 }}
                    align={'center'}
                    w={'full'}
                    justify={'center'}
                >
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}
                    >
                        Let's put your business
                        <br />
                        <Text as={'span'} color={'pink.400'}>
                            Online
                        </Text>
                    </Heading>
                    <Text fontSize={'18px'}>
                        Elementary creative is your solution to a complete
                        digital takeover. <br />
                        We help you organize and optimize your business.
                    </Text>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}
                    >
                        <Link to="/portfolio">
                            <Button
                                colorScheme={'pink'}
                                bg={'pink.400'}
                                rounded={'full'}
                                px={6}
                                _hover={{
                                    bg: '#7360DF',
                                }}
                            >
                                Portfolio
                            </Button>
                        </Link>
                    </Stack>
                </Stack>
            </Container>
        </>
    )
}
