import {
    ChevronDownIcon,
    ChevronRightIcon,
    CloseIcon,
    EmailIcon,
    HamburgerIcon,
} from '@chakra-ui/icons'
import {
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    IconButton,
    Link,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react'
import { BsMailbox, BsMailbox2, BsTelephoneFill } from 'react-icons/bs'
import { Link as RouteLink } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { COMMON_NAVLINKS } from '../../constants'

export default function Navbar() {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box width={'100%'} alignContent="center">
            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                justify={'space-between'}
                py={{ base: 2 }}
                px={{ base: 2 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
            >
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: 2 }}
                    display={{ base: 'flex', md: 'none' }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex
                    flex={{ base: 1 }}
                    justify={{ base: 'center', md: 'start' }}
                    alignItems={'center'}
                >
                    <Text
                        textAlign={useBreakpointValue({
                            base: 'center',
                            md: 'left',
                        })}
                        fontFamily={'heading'}
                        color={useColorModeValue('gray.800', 'white')}
                    >
                        <RouteLink to="/">
                            <Box
                                ml={{ base: 0, md: 4 }}
                                width={{ base: '88%', md: '58%' }}
                            >
                                <img
                                    src={logo}
                                    width="100%"
                                    alt="Elementary Creative"
                                />
                            </Box>
                        </RouteLink>
                    </Text>

                    <Flex
                        align={'center'}
                        display={{ base: 'none', md: 'flex' }}
                        ml={{ base: 0, lg: 6 }}
                    >
                        <DesktopNav />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    mr={{ base: 5, md: 10 }}
                    direction={'row'}
                    spacing={{ base: 'none', md: '6' }}
                >
                    <Button
                        as={'a'}
                        fontSize={{ base: 'xs', md: 'sm' }}
                        fontWeight={400}
                        variant={'outline'}
                        colorScheme="pink"
                        borderWidth={useBreakpointValue({
                            base: 0,
                            sm: 1,
                        })}
                        href={'tel:1-639-316-4005'}
                    >
                        <Text
                            display={useBreakpointValue({
                                base: 'none',
                                sm: 'block',
                            })}
                        >
                            Call Us &nbsp;
                        </Text>
                        <Icon
                            as={BsTelephoneFill}
                            color={'pink.500'}
                            w={5}
                            h={5}
                        />
                    </Button>
                    <RouteLink to="/contact">
                        <Button
                            as={'a'}
                            fontSize={{ base: 'xs', md: 'sm' }}
                            fontWeight={400}
                            variant={'outline'}
                            colorScheme="pink"
                            borderWidth={useBreakpointValue({
                                base: 0,
                                sm: 1,
                            })}
                            href={'mailto:contact@elementarycreative.com'}
                        >
                            <Text
                                display={useBreakpointValue({
                                    base: 'none',
                                    sm: 'block',
                                })}
                            >
                                Email Us &nbsp;
                            </Text>
                            <Icon
                                as={EmailIcon}
                                color={'pink.500'}
                                w={5}
                                h={5}
                            />
                        </Button>
                    </RouteLink>
                    {/* <RouteLink to={'/contact'}>
                        <Button
                            display={{ base: 'none', md: 'inline-flex' }}
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg={'pink.400'}
                            _hover={{
                                bg: '#7360DF',
                            }}
                        >
                            Contact
                        </Button>
                    </RouteLink> */}
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    )
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200')
    const linkHoverColor = useColorModeValue('pink.50', 'gray.800')
    const popoverContentBgColor = useColorModeValue('#fff', 'gray.800')

    return (
        <Stack direction={'row'} wrap={'wrap'} spacing={2}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <RouteLink to={{ pathname: navItem.href }}>
                                <Link
                                    px={{ base: 0, lg: 4 }}
                                    py={{ base: 0, lg: 3 }}
                                    href={navItem.href ?? '#'}
                                    fontSize={{ base: 'sm', lg: 'xl' }}
                                    fontWeight={500}
                                    color={linkColor}
                                    transition={'all .3s ease'}
                                    borderRadius={'2px'}
                                    _hover={{
                                        backgroundColor: '#f7f7f7',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {navItem.label}
                                </Link>
                            </RouteLink>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                w={'auto'}
                            >
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav
                                            key={child.label}
                                            {...child}
                                        />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    )
}

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <RouteLink to={href || '#'}>
            <Box
                role={'group'}
                display={'block'}
                p={1}
                justifyContent={'start'}
                _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
            >
                <Stack direction={'row'}>
                    <Flex direction={'column'} justifyContent={'start'}>
                        <Text
                            fontSize={'md'}
                            transition={'all .3s ease'}
                            _groupHover={{ color: 'pink.400' }}
                            fontWeight={500}
                        >
                            {label}
                        </Text>
                        <Text fontSize={'xs'}>{subLabel}</Text>
                    </Flex>
                    <Flex
                        transition={'all .3s ease'}
                        transform={'translateX(-10px)'}
                        opacity={0}
                        _groupHover={{
                            opacity: '100%',
                            transform: 'translateX(0)',
                        }}
                        justify={'flex-end'}
                        align={'center'}
                        flex={1}
                    >
                        <Icon
                            color={'pink.400'}
                            w={5}
                            h={5}
                            as={ChevronRightIcon}
                        />
                    </Flex>
                </Stack>
            </Box>
        </RouteLink>
    )
}

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}
        >
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    )
}

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure()

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: '0!important' }}
            >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                >
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    )
}

interface NavItem {
    label: string
    subLabel?: string
    children?: Array<NavItem>
    href?: string
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: COMMON_NAVLINKS.home.label,
        href: COMMON_NAVLINKS.home.link,
    },
    {
        label: COMMON_NAVLINKS.portfolio.label,
        href: COMMON_NAVLINKS.portfolio.link,
    },
    {
        label: COMMON_NAVLINKS.services.label,
        children: [
            {
                label: 'Website Development',
                subLabel: 'Trending designs that inspire',
                href: 'services/#website',
            },
            {
                label: 'Graphic Design',
                subLabel: 'For your peace of mind',
                href: 'services/graphic-design',
            },
        ],
    },
    {
        label: COMMON_NAVLINKS.contact.label,
        href: COMMON_NAVLINKS.contact.link,
    },
    // {
    //     label: COMMON_NAVLINKS.cdapGrant.label,
    //     href: COMMON_NAVLINKS.cdapGrant.link,
    // },

    //   {
    //   label: 'Inspiration',
    //   children: [
    //     {
    //       label: 'Explore Design Work',
    //       subLabel: 'Trending Design to inspire you',
    //       href: '#',
    //     },
    //     {
    //       label: 'New & Noteworthy',
    //       subLabel: 'Up-and-coming Designers',
    //       href: '#',
    //     },
    //   ],
    // },
    // {
    //   label: 'Find Work',
    //   children: [
    //     {
    //       label: 'Job Board',
    //       subLabel: 'Find your dream design job',
    //       href: '/',
    //     },
    //     {
    //       label: 'Freelance Projects',
    //       subLabel: 'An exclusive list for contract work',
    //       href: '/',
    //     },
    //   ],
    // },
]
