import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Features from '../../components/Features/Features'
import Hero from '../../components/Hero/Hero'
import Story from '../../components/Story/Story'
import Testimonials from '../../components/Testimonials/Testimonials'

export default function Homepage() {
    const { pathname, hash } = useLocation()

    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0)
        }
    }, [pathname, hash])
    return (
        <>
            <Hero />
            <Story />
            <Features />
            <Testimonials />
        </>
    )
}
