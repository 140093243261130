import {
    chakra,
    Stack,
    HStack,
    Text,
    Icon,
    useColorModeValue,
    Box,
} from '@chakra-ui/react'
import { BsFillCheckCircleFill } from 'react-icons/bs'

interface PricingCardProps {
    plan: {
        title: string
        price: number
        features: string[]
        stars: number
        width?: string
    }
}

const PricingCard = ({ plan }: PricingCardProps) => {
    return (
        <Stack
            w={{ base: 'xs', lg: '3xs', xl: plan.width }}
            h={'auto'}
            spacing={5}
            px={10}
            py={6}
            mb={5}
            border="1px solid"
            borderColor={useColorModeValue('gray.400', 'gray.600')}
            rounded="md"
            textAlign="center"
        >
            <Box backgroundColor={'gray.200'} borderRadius={8} py={3}>
                <chakra.h1 fontSize="xl" fontWeight="bold" textAlign="center">
                    {plan.title}
                </chakra.h1>
            </Box>

            <chakra.h1 fontSize="5xl" fontWeight="400">
                {`${plan.price} `}
            </chakra.h1>

            {plan.features.map((text, index) => (
                <HStack
                    key={index}
                    spacing={3}
                    pb={2}
                    borderBottom={
                        index === plan.features.length - 1
                            ? 'none'
                            : '2px solid #dddddd'
                    }
                >
                    <Icon
                        as={BsFillCheckCircleFill}
                        h={6}
                        w={6}
                        color="green.400"
                    />
                    <Text align={'left'} fontSize="lg" color="gray.500">
                        {text}
                    </Text>
                </HStack>
            ))}
        </Stack>
    )
}

export default PricingCard
