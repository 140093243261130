import {
    AbsoluteCenter,
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Icon,
    Input,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    VStack,
    VisuallyHidden,
    chakra,
    useColorModeValue,
} from '@chakra-ui/react'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { InlineWidget, PopupButton } from 'react-calendly'
import { BsPhone, BsTelephone } from 'react-icons/bs'
import { GoLocation } from 'react-icons/go'
import { HiOutlineMail } from 'react-icons/hi'

const contactOptions = [
    {
        label: 'Where are we?',
        value: 'Vancouver, Canada',
        icon: GoLocation,
    },
    {
        label: 'Phone number',
        value: '+1-639-316-4005',
        icon: BsPhone,
        link: 'tel:16393164005',
    },
    {
        label: 'Email address',
        value: 'contact@elementarycreative.com',
        icon: HiOutlineMail,
        link: 'mailto:contact@elementarycreative.com',
    },
]

const ContactSection = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telephone, setTelePhone] = useState('')
    const [message, setMessage] = useState('')
    const [formResponse, setFormResponse] = useState('')
    const [error, setError] = useState('')
    const [submitting, setSubmitting] = useState(false)

    useEffect(() => {
        window.scroll(0, 0)
    })

    const handleSubmit = async (event: React.ChangeEvent<any>) => {
        event.preventDefault()
        const endpoint =
            'https://egpmgusqyi.execute-api.us-east-1.amazonaws.com/PROD/contact'

        try {
            setSubmitting(true)
            await axios.post(endpoint, {
                name,
                email,
                telephone,
                message,
            })
            setFormResponse(
                'We have received your request. We will get back to you shortly.'
            )
        } catch (e) {
            setError(
                'Something went wrong while submitting the form. Please try again.'
            )
            console.error('Something went wrong while submitting the form', e)
        } finally {
            setName('')
            setEmail('')
            setTelePhone('')
            setMessage('')
            setSubmitting(false)
        }
    }

    return (
        <Box
            maxW={'8xl'}
            mt={{ base: 0, lg: 6 }}
            minH={'100vh'}
            py={{ base: 4, lg: 12 }}
            mx="auto"
            id="contact"
        >
            <Flex
                align={{ base: 'center', md: 'center' }}
                flexDir={{ base: 'column-reverse', md: 'row' }}
                mt={12}
                w={{ base: 'xs', sm: 'xl', md: '2xl', lg: '4xl', xl: '7xl' }}
                justifyContent={'space-evenly'}
            >
                <Box mb={{ base: 6, lg: 0 }} mt={{ base: 12, lg: 0 }}>
                    <VStack spacing={10}>
                        <Box>
                            <chakra.h1
                                display={{ base: 'none', xl: 'block' }}
                                fontSize={{ base: '3xl', md: '4xl' }}
                                fontWeight="bold"
                                lineHeight={{ base: 'shorter', md: 'none' }}
                                color={useColorModeValue(
                                    'gray.900',
                                    'gray.200'
                                )}
                                letterSpacing={{ base: 'normal', md: 'tight' }}
                            >
                                Ready to start your journey?
                            </chakra.h1>
                        </Box>
                        <SimpleGrid
                            columns={{ base: 1, xl: 2 }}
                            mb={{ base: 12, md: 2 }}
                            rowGap={10}
                        >
                            {contactOptions.map((option, index) => (
                                <Fragment key={index}>
                                    <Stack
                                        spacing={3}
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        px={3}
                                    >
                                        <Icon
                                            as={option.icon}
                                            w={10}
                                            h={10}
                                            color="pink.300"
                                        />
                                        <Text
                                            fontSize="xl"
                                            fontWeight="semibold"
                                        >
                                            {option.label}
                                        </Text>
                                        {option.link ? (
                                            <a href={option.link}>
                                                <Text
                                                    sx={styles.link}
                                                    padding={'6px 8px'}
                                                    fontSize="md"
                                                    textAlign="center"
                                                >
                                                    {option.value}
                                                </Text>
                                            </a>
                                        ) : (
                                            <Text
                                                sx={styles.link}
                                                fontSize="md"
                                                textAlign="center"
                                            >
                                                {option.value}
                                            </Text>
                                        )}
                                    </Stack>
                                </Fragment>
                            ))}
                            <Stack
                                spacing={3}
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                px={3}
                            >
                                <Icon
                                    as={BsTelephone}
                                    w={10}
                                    h={10}
                                    color="pink.300"
                                />
                                <Text fontSize="xl" fontWeight="semibold">
                                    {'Consultation'}
                                </Text>
                                {
                                    <PopupButton
                                        url="https://calendly.com/elementarycreative/30min"
                                        /*
                                         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                                         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                                         */
                                        rootElement={
                                            document.getElementById('root')!
                                        }
                                        text="Free 30 Min Consulation"
                                        styles={styles.consultationButton}
                                    />
                                }
                            </Stack>
                        </SimpleGrid>
                        <Box w={'full'} display={{ base: 'none', xl: 'none' }}>
                            <iframe
                                title="map"
                                src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d184365.7058525471!2d-122.66208623613309!3d49.18985933187416!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sca!4v1705371041421!5m2!1sen!2sca"
                                width="100%"
                                style={{ height: 350 }}
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </VStack>
                </Box>

                <Box w={{ base: 'xs', md: 'sm' }}>
                    <VStack align={'center'}>
                        <Box
                            minH={500}
                            as="form"
                            mb={1}
                            rounded="lg"
                            w={{ base: 'xs', lg: 'sm' }}
                            boxShadow={
                                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                            }
                        >
                            <Center
                                color={useColorModeValue(
                                    'gray.700',
                                    'gray.600'
                                )}
                            >
                                <chakra.h1
                                    my={4}
                                    fontSize={{ base: 'xl', md: '2xl' }}
                                    fontWeight="bold"
                                    lineHeight={{ base: 'shorter', md: 'none' }}
                                    color={useColorModeValue(
                                        'gray.900',
                                        'gray.200'
                                    )}
                                    letterSpacing={{
                                        base: 'normal',
                                        md: 'tight',
                                    }}
                                >
                                    Contact Us
                                </chakra.h1>
                            </Center>
                            <SimpleGrid
                                columns={1}
                                px={6}
                                py={4}
                                spacing={4}
                                borderBottom="solid 1px"
                                borderColor={useColorModeValue(
                                    'gray.200',
                                    'gray.700'
                                )}
                            >
                                <Flex>
                                    <VisuallyHidden>First Name</VisuallyHidden>
                                    <Input
                                        mt={0}
                                        type="text"
                                        placeholder="First Name"
                                        required={true}
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </Flex>
                                <Divider orientation="vertical" />
                                <Flex>
                                    <VisuallyHidden>
                                        Email Address
                                    </VisuallyHidden>
                                    <Input
                                        mt={0}
                                        type="email"
                                        placeholder="Email Address"
                                        required={true}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Flex>
                                <Divider orientation="vertical" />
                                <Flex>
                                    <VisuallyHidden>
                                        telePhone Number
                                    </VisuallyHidden>
                                    <Input
                                        mt={0}
                                        type="tel"
                                        placeholder="Phone Number"
                                        required={true}
                                        value={telephone}
                                        onChange={(e) =>
                                            setTelePhone(e.target.value)
                                        }
                                    />
                                </Flex>
                                <Flex>
                                    <VisuallyHidden>Message</VisuallyHidden>
                                    <Textarea
                                        minHeight={'160px'}
                                        mt={0}
                                        placeholder="Message"
                                        required={true}
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                    />
                                </Flex>
                                <Flex px={6} py={4}>
                                    <Button
                                        onClick={(e) => handleSubmit(e)}
                                        py={2}
                                        w="full"
                                        colorScheme="pink"
                                        background={'pink.400'}
                                        isLoading={submitting}
                                        loadingText="Submitting"
                                        _hover={{
                                            bg: '#7360DF',
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Flex>
                                <Flex>
                                    {error && (
                                        <Alert status="error">
                                            <AlertIcon />
                                            {error}
                                        </Alert>
                                    )}

                                    {formResponse && (
                                        <Alert status="success">
                                            <AlertIcon />
                                            {formResponse}
                                        </Alert>
                                    )}
                                </Flex>
                            </SimpleGrid>
                        </Box>
                    </VStack>
                </Box>
            </Flex>
            {/* <Box position="relative" padding="10">
                <Divider borderWidth={1} borderColor={'blue.300'} />
                <AbsoluteCenter bg="white" px="4">
                    OR
                </AbsoluteCenter>
            </Box> */}

            <Box mt={12} w={'full'} display={{ base: 'block', xl: 'block' }}>
                <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d184365.7058525471!2d-122.66208623613309!3d49.18985933187416!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sca!4v1705371041421!5m2!1sen!2sca"
                    width="100%"
                    style={{ height: 350 }}
                    loading="lazy"
                ></iframe>
            </Box>
        </Box>
    )
}
const styles = {
    link: {
        borderRadius: '4px',
        padding: '6px 8px',
        borderBottom: `1px solid #A9A9A9`,
    },
    consultationButton: {
        outline: '0',
        gridGap: '8px',
        alignItems: 'center',
        backgroundColor: 'pink.300',
        color: '#000',
        borderBottom: `1px solid #A9A9A9`,
        borderRadius: '4px',
        padding: '6px 8px',
        cursor: 'pointer',
        display: 'inline-flex',
        flexShrink: 0,
        fontSize: '16px',
        gap: '8px',
        justifyContent: 'center',
        lineHeight: 1.5,
        overflow: 'hidden',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        transition: 'all .14s ease-out',
        ':hover': {
            boxShadow: '4px 4px 0 #000',
            transform: 'translate(-4px,-4px)',
        },
        ':focus-visible': { outlineOffset: '1px' },
    },
}

export default ContactSection
